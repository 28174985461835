import buyer_dom_manage_notice_list from '@/lib/data-service/flight/buyer_dom_manage_notice_list';
import { ref, defineComponent } from '@vue/composition-api'
//import VueOfficeExcel from '@vue-office/excel'
//import '@vue-office/excel/lib/index.css'

import jsPreviewExcel from '@js-preview/excel'
import '@js-preview/excel/lib/index.css'
export default {
    data() {
        return {
            loading: false,
            form: {
                "airlineCode": "",
                "createTimeBegin": "",
                "createTimeEnd": "",
                "currentPage": 1,
                "pageSize": 10,
                "productType": '',
                createTime: [],
                total: 0,
            },
            productTypeList: [{value: '', label: '全部',},{value: 1, label: '标品',},{value: 2, label: '特殊',}],
            list: [],
            dialogVisible: false,
            url: '',
            urlName: '',
            loadingDialog: false,
        }
    },
    props: {},
    components: {},
    created() {
    },
    mounted() {
        this._search();
    },
    activated() {
        // this._search();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _inputEnglish(val) {
            this.form.airlineCode = val.replace(/[^a-zA-Z0-9]/g, '')
            this.$forceUpdate()
        },
        _changeTime(val) {
            if (val) {
                this.form.createTimeBegin = val[0] + ' 00:00:00';
                this.form.createTimeEnd = val[1] + ' 23:59:59';
            } else {
                this.form.createTimeBegin = '';
                this.form.createTimeEnd = '';
            }
        },
        _search() {
            this.form.currentPage = 1;
            this._getList();
        },
        _getList() {
            this.loading = true;
            buyer_dom_manage_notice_list(this.form).then(res=>{
                this.loading = false;
                this.form.total = res.pageResult.totalCount;
                this.list = res.pageResult.pageData;
            }).catch(e=>{
                this.loading = false;
                this.list = [];
            })
        },
        _sizeChange(val) {
            this.form.pageSize = val;
            this._getList();
        },
        _pageChange(val) {
            this.form.currentPage = val;
            this._getList();
        },
        async _view(item) {
            if (!item.fileUrl) {
                return;
            }
            this.url = item.fileUrl;
            this.urlName = item.title;
            // this.url = 'https://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2025/02/21/17/59320597-7ffc-458f-8b39-d66cc3e347d1/政策2-公有政策管理DZ-G5-HO.xlsx';
            // this.urlName = '政策2-公有政策管理DZ-G5-HO';
            this.dialogVisible = true;
            this.loadingDialog = true;
            this.$nextTick(()=>{
                const container = document.getElementById('excel');
                container.innerHTML = ''; // 清空容器内容
                const excelPreviewer = jsPreviewExcel.init(container);
                excelPreviewer.preview(this.url).then(res => {
                    this.loadingDialog = false;
                });

            })
        },
        _download(url) {
            if (!url) {
                return;
            }
            window.open(url);
        }
    }
}
